<template>
  <div id="financial-plan-thanks">
    <section class="bg-green3 text-center">
      <div class="container">
        <router-link to="/" class="mb-4 d-inline-block">
          <img src="/nedcollege/NEDCOLLEGE_logo_blue.svg" alt="" height="75" />
        </router-link>

        <h2 v-html="lang.title" />
      </div>
    </section> 
    <div class="float-section">
      <div class="container">
        <img :src="lang.ebook_thumb" alt="" class="ebook-thumb" />
      </div>
    </div>
    <section class="bg-blue1">
      <div class="container text-center">
        <h4 class="text-green3">
          {{ lang.first_step.title }}
        </h4>
        <p>{{ lang.first_step.subtitle }}</p>
        <p class="fw-bold">{{ lang.first_step.bold }}</p>
      </div>
    </section>
    <section class="bg-blue4">
      <div class="container text-center">
        <h4 class="mb-4">
          {{ lang.socials.title }}
        </h4>
        <p class="fw-bold text-green1">
          {{ lang.socials.subtitle }}
        </p>

        <div class="btn-group">
          <a href="https://www.instagram.com/nedcollege/" target="_blank" class="btn btn-blue2 text-white btn-circle">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/NEDCollege/" target="_blank" class="btn btn-blue2 text-white btn-circle">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
          <a href="https://www.youtube.com/user/nedtcschool" target="_blank"
            class="btn btn-blue2 text-white btn-circle">
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/nedcollege" target="_blank"
            class="btn btn-blue2 text-white btn-circle">
            <i class="fa-brands fa-linkedin"></i>
          </a>
          <a href="https://www.tiktok.com/@nedcollege" target="_blank" class="btn btn-blue2 text-white btn-circle">
            <i class="fa-brands fa-tiktok"></i>
          </a>
        </div>
      </div>
    </section>
    <footer class="bg-blue2 text-center">
      <router-link to="/" class="mb-4 d-inline-block">
        <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" alt="" height="75" />
      </router-link>

      <p class="m-0 text-green3">
        <b>{{ lang.footer.campus }}</b>
      </p>
      <p><small>© 2024 NED College</small></p>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    lang() {
      return this.$lang();
    },
  },
};
</script>
